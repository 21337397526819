import React, { useEffect, useState, useRef } from 'react';
import '../../styling/LocationOfInjuries.css';
import { Box, Button, Typography } from '@mui/joy';


export const LocationOfInjuries = (props) => {
  const {initialValue, onChange, label, disabled} = props
  const [selectedParts, setSelectedParts] = useState(initialValue);
  const [isFrontView, setIsFrontView] = useState(true);
  const modified = useRef(false)

  const toggleBodyPart = (partName) => {
    setSelectedParts((prevParts) =>
      prevParts.includes(partName)
        ? prevParts.filter((part) => part !== partName)
        : [...prevParts, partName]
    );
  };

  const displayText = () => {
    let displayString = ""
    
    for (let index = 0; index < selectedParts.length; index++) {
      if (index === 0) {
        displayString += selectedParts[index];
      } else {
        displayString += ", " + selectedParts[index];
      }
    }
    return displayString
  }

  useEffect(() => {
    if (onChange && modified.current) {
      onChange(selectedParts);
    }
  }, [selectedParts])

  const handleClick = (event) => {
    if (disabled) {
      return;
    }
    const partName = event.target.id;
    if (partName) {
      if (!modified.current) {
        modified.current = true;
      }
      toggleBodyPart(partName);
    }
  };

  const toggleView = () => {
    setIsFrontView(!isFrontView);
  };

  return (
    <div className="container">
      <div className="body-model">
        {label}:
        {isFrontView ? (
          <div className="front-view">
            <div
              id="Head"
              className={`body-part ${selectedParts.includes('Head') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="Neck"
              className={`body-part ${selectedParts.includes('Neck') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LeftChest"
              className={`body-part ${selectedParts.includes('Left Chest') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="RightChest"
              className={`body-part ${selectedParts.includes('Right Chest') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="Stomach"
              className={`body-part ${selectedParts.includes('Stomach') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="UpperLeftArm"
              className={`body-part ${selectedParts.includes('Upper Left Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LowerLeftArm"
              className={`body-part ${selectedParts.includes('Lower Left Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="UpperRightArm"
              className={`body-part ${selectedParts.includes('Upper Right Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LowerRightArm"
              className={`body-part ${selectedParts.includes('Lower Right Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="UpperLeftLeg"
              className={`body-part ${selectedParts.includes('Upper Left Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LowerLeftLeg"
              className={`body-part ${selectedParts.includes('Lower Left Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="UpperRightLeg"
              className={`body-part ${selectedParts.includes('Upper Right Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="LowerRightLeg"
              className={`body-part ${selectedParts.includes('Lower Right Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
          </div>
        ) : (
          <div className="back-view">
            <div
              id="BackHead"
              className={`body-part ${selectedParts.includes('Back Head') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackNeck"
              className={`body-part ${selectedParts.includes('Back Neck') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLeftShoulder"
              className={`body-part ${selectedParts.includes('Back Left Shoulder') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackRightShoulder"
              className={`body-part ${selectedParts.includes('Back Right Shoulder') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackUpperLeftArm"
              className={`body-part ${selectedParts.includes('Back Upper Left Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerLeftArm"
              className={`body-part ${selectedParts.includes('Back Lower Left Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackUpperRightArm"
              className={`body-part ${selectedParts.includes('Back Upper Right Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerRightArm"
              className={`body-part ${selectedParts.includes('Back Lower Right Arm') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackUpperLeftLeg"
              className={`body-part ${selectedParts.includes('Back Upper Left Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerLeftLeg"
              className={`body-part ${selectedParts.includes('Back Lower Left Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackUpperRightLeg"
              className={`body-part ${selectedParts.includes('Back Upper Right Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
            <div
              id="BackLowerRightLeg"
              className={`body-part ${selectedParts.includes('Back Lower Right Leg') ? 'highlighted' : ''}`}
              onClick={handleClick}
            >
            </div>
          </div>
        )}
      </div>
      <Box sx={{width:"200px", height: '400px'}}>
        <Button onClick={toggleView} sx={{height: "50px"}}>
          {isFrontView ? 'Posterior' : 'Anterior'}
        </Button>
        <Typography>
          {displayText(selectedParts)}
        </Typography>
      </Box>
    </div>
  );
};

